import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { invokeApi } from "src/bl_libs/invokeApi";
import { s3baseUrl } from "src/config/config";

export default function GeneralCkeditor({
  inputs,
  setInputs,
  name,
  editorHeight,
}) {
  const editorRef = useRef(null);
  const [focuse, setfocuse] = useState(false);
  const log = () => {
    if (editorRef.current) {
      setInputs((prevState) => ({
        ...prevState,
        [name]: editorRef.current.getContent(),
      }));
    }
  };
  function handleEditorInit() {
    setTimeout(() => {
      // let container = document.getElementsByClassName(
      //   "tox-notifications-container"
      // );
      let containerWarning = document.getElementsByClassName(
        "tox-notification--warning"
      );
      if (containerWarning.length > 0) {
        containerWarning[0].style.display = "none";
        setfocuse(false);
      }
      setfocuse(false);
    }, 100);
  }

  function example_image_upload_handler(blobInfo, success, failure, progress) {
    let requestObj = {
      path: "/app/update_image_on_s3/",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let _formData = new FormData();
    _formData.append("image", blobInfo.blob());
    _formData.append("width", "600");
    requestObj["postData"] = _formData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        success(s3baseUrl + res.image_path);
      } else {
        failure(res.message);
      }
    });
  }
  useEffect(() => {
    handleEditorInit();
  }, [focuse]);
  return (
    <>
      <Editor
        apiKey="yppqzjiz7q9kkiuzs7l47j4z22jn9flw36vlhv85q98ue4zd"
        onChange={log}
        value={inputs[name]}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={(newValue, editor) => {
          log(newValue, editor);
          setfocuse(true);
        }}
        init={{
          setup: function (editor) {
            editor.on("focus", function (e) {
              console.log("TinyMCE Editor Focused");
              setfocuse(true);
            });
          },
          images_upload_handler: example_image_upload_handler,
          height: editorHeight ? editorHeight : 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </>
  );
}
