import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { TimeZones } from "src/utils/constant";
import countryList from "react-select-country-list";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress, Checkbox } from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { menuList } from "src/DAL/Menu/Menu";
import { AddProgrammeApi } from "src/DAL/Programme/Programme";
import { countries } from "src/utils/country";
import { AddMemberApi, EditMemberApi } from "src/DAL/member/member";
import { consultantListing } from "src/DAL/consultant/consultant";
import { s3baseUrl } from "src/config/config";
import { height } from "@mui/system";
import ActiveLastBreadcrumb from "src/components/ActivePaths";
import { useEffect } from "react";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function EditMember() {
  const navigate = useNavigate();
  const classes = useStyles();
  const param = useParams();
  const { state, pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setiswaiting] = useState(false);
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [audioFile, setAudio] = React.useState();
  const [groups, setGroups] = React.useState([]);
  const [country, setCountry] = React.useState("");
  const [menuLists, setMenuList] = React.useState([]);
  const [date, setDate] = React.useState(null);
  const [programName, setProgramName] = React.useState("");
  const [vaultName, setVaultName] = React.useState("");
  const [navItems, setNavitems] = React.useState([]);
  const [consultantValue, setConsultantValue] = React.useState([]);
  const [oldImage, setOldImage] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [selectCountry, setSelectCountry] = useState("");
  const [imageStatus, setImageStatus] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = React.useState("Europe/Dublin");
  const [inputValue, setInputValue] = React.useState("");
  const [checked, setChecked] = React.useState();
  const [postalCountry, setPostalCountry] = React.useState("");
  const [breadCrumbMenu, setBreadCrumbMenu] = useState([
    {
      title: "Members",
      navigation: `/member`,
      active: false,
    },

    {
      title: "Edit Member",
      navigation: null,
      active: true,
    },
  ]);
  const [inputs, setInputs] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    contact_number: "",
    goal_status: "",
    member_status: "",
    street: "",
    city: "",
    zip_code: "",
    state: "",
    consultant_id: "",
    first_payment_date: new Date(),
    member_biography: "",
    image: {},
    short_description: "",
    time_zone: "",
    coins_count: "",
    referral_name: "",
    is_membership_purchase: "",
    is_executive: "",
    street2: "",
    postalStreet: "",
    postalStreet2: "",
    postalZip_code: "",
    postalState: "",
    postalCity: "",
    school_name: "",
    max_teacher_limit: 1,
  });

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImageStatus(true);
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (country == "" || country == undefined) {
      enqueueSnackbar("Country is required", {
        variant: "error",
      });
    } else {
      if (inputs.days < 0) {
        enqueueSnackbar("No of days must be positive number ", {
          variant: "error",
        });
      } else {
        if (inputs.max_teacher_limit > 999) {
          enqueueSnackbar("Teacher Count cannot be greater than 999", {
            variant: "error",
          });
        } else {
          const formData = new FormData();
          formData.append("first_name", inputs.first_name);
          formData.append("max_teacher_limit", inputs.max_teacher_limit);
          formData.append("last_name", inputs.last_name);
          formData.append("member_biography", inputs.short_description);
          formData.append("email", inputs.email);
          formData.append("school_name", inputs.school_name);
          formData.append("contact_number", inputs.contact_number);
          formData.append("status", inputs.member_status);
          formData.append("street", inputs.street);
          formData.append("city", inputs.city);
          formData.append("zip_code", inputs.zip_code);
          formData.append("state", inputs.state);
          formData.append("country", country?.code);
          formData.append(
            "consultant_id",
            inputs.consultant_id == undefined ? "" : inputs.consultant_id
          );
          formData.append(
            "first_payment_date",
            date ? moment(date).format("YYYY-MM-DD") : ""
          );
          formData.append("goal_statement_status", inputs.goal_status);
          formData.append("time_zone", timeZoneValue);
          if (imageStatus == true) {
            formData.append("profile_image", inputs.image);
          }

          setIsLoading(true);
          const result = await EditMemberApi(formData, state.id);
          if (result.code === 200) {
            enqueueSnackbar(result.message, { variant: "success" });
            navigate(-1);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        }
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const {
    id,
    name,
    lastName,
    status,
    email,
    profile,
    program,
    goal,
    contact_number,
    street,
    city,
    zip_code,
    consultant_id,
    time_zone,
    member_biography,
    profile_image,
    first_payment_date,
    selected_country,
    coinsCount,
    postal_addess_info,
    max_teacher_limit,
    school_name,
  } = state;
  console.log(state, "statestatestate");
  React.useEffect(() => {
    let obj = {};
    if (state?.is_postal_address_same) {
      let beforePars =
        postal_addess_info == undefined
          ? ""
          : JSON.stringify(postal_addess_info);

      obj = beforePars == "" ? "" : JSON.parse(beforePars);
    } else {
      let strigifyObj = JSON.stringify(postal_addess_info);
      obj = postal_addess_info && JSON.parse(strigifyObj);
    }

    let postalInfo = obj;

    setChecked(state.is_postal_address_same);
    let findCountry = countries.find((item) => {
      if (String(item.code) === String(selected_country)) {
        return item;
      }
    });
    let findPostalCountry = countries.find((item) => {
      if (String(item.code) === String(postalInfo?.country)) {
        return item;
      }
    });
    setDate(
      first_payment_date == "" || first_payment_date == "Invalid date"
        ? null
        : first_payment_date
    );
    setOldImage(profile_image);
    setSelectCountry(selected_country);
    setCountry(findCountry);
    setPostalCountry(findPostalCountry);
    setTimeZoneValue(time_zone);
    setInputs((inputs) => ({
      ...inputs,
      ["first_name"]: name,
      ["max_teacher_limit"]: max_teacher_limit,
      ["last_name"]: lastName,
      ["last_name"]: lastName,
      ["email"]: email,
      ["contact_number"]: contact_number,
      ["member_status"]: status,
      ["goal_status"]: goal,
      ["street"]: street,
      ["city"]: city,
      ["state"]: state.state,
      ["zip_code"]: zip_code,
      ["time_zone"]: time_zone,
      ["consultant_id"]: consultant_id,
      ["short_description"]: member_biography,
      ["school_name"]: school_name,
    }));
  }, []);

  useEffect(() => {
    if (pathname.match("/consultant/")) {
      setBreadCrumbMenu([
        {
          title: "Success Ambassador",
          navigation: `/consultant`,
          active: false,
        },
        {
          title: "Members",
          navigation: `/consultant/member-list/${param.consultantId}`,
          active: false,
        },
        {
          title: "Edit Member",
          navigation: null,
          active: true,
        },
      ]);
    }
  }, [pathname]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <h2>Edit Member</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            fullWidth
            required
            name="first_name"
            value={inputs.first_name}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            fullWidth
            name="last_name"
            value={inputs.last_name}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            defaultValue="Hello World"
            fullWidth
            disabled
            required
            name="email"
            value={inputs.email}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="School Name"
            variant="outlined"
            fullWidth
            required
            name="school_name"
            value={inputs.school_name}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Contact Number"
            variant="outlined"
            fullWidth
            name="contact_number"
            value={inputs.contact_number}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Maximum Teachers Count"
            variant="outlined"
            fullWidth
            required
            type="number"
            name="max_teacher_limit"
            onWheel={(event) => event.target.blur()}
            value={inputs.max_teacher_limit}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Member Status *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="member_status"
              value={inputs.member_status}
              label="Member Status *"
              onChange={handleChange}>
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image </p>
              <FormHelperText className="pt-0">
                Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img className="image-border" src={file} height="50" />
              ) : (
                oldImage && (
                  <img
                    className="image-border"
                    src={s3baseUrl + oldImage}
                    height="50"></img>
                )
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span">
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image.name}</p>
          )}
        </div>

        <div className="col-12">
          <h3>Address Details</h3>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <Autocomplete
            id="country-select-demo"
            // sx={{ width: 300 }}
            options={countries}
            autoHighlight
            value={country}
            onChange={(event, newValue) => {
              setCountry(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}>
                <img
                  className="image-border"
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country *"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="City"
            variant="outlined"
            fullWidth
            name="city"
            required
            value={inputs.city}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="State"
            variant="outlined"
            fullWidth
            required
            name="state"
            value={inputs.state}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Street Address 1"
            variant="outlined"
            fullWidth
            required
            name="street"
            value={inputs.street}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Zip Code"
            variant="outlined"
            fullWidth
            required
            name="zip_code"
            value={inputs.zip_code}
            onChange={handleChange}
          />
        </div>

        <Divider className="mt-2" />
        <h3 className="mt-3">Configurations</h3>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date of first payment"
              inputFormat="dd/MM/yyyy"
              value={date}
              onChange={handleChangeDate}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <Autocomplete
            value={timeZoneValue}
            onChange={(event, newValue) => {
              setTimeZoneValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={TimeZones}
            renderInput={(params) => (
              <TextField {...params} label="Time Zone *" />
            )}
          />
        </div>
        <div className="col-12 mt-5">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description "
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
            <FormHelperText>
              Member Biography (Maximum limit 500 characters)
            </FormHelperText>
          </FormControl>
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
